<template>
  <van-form
    :class="{
      'van-form__text-color': !isAdd
    }"
    :show-error-message="false"
    label-width="26vw"
    @submit="onSubmit"
  >
    <van-field
      :disabled="true"
      :value="delayFixInfo.applyDate"
      label="延期日期"
      readonly
    />
    <van-field
      v-model="delayFixInfo.reason"
      :disabled="true"
      label="延期原因"
      rows="2"
      type="textarea"
    />
    <van-field
      v-if="isAdd"
      :required="isAdd"
      :rules="[{ required: true }]"
      label="审批意见"
      name="passStatus"
    >
      <template #input>
        <van-radio-group
          v-model="info.applyStatus"
          :disabled="!isAdd"
          direction="horizontal"
        >
          <van-radio
            v-for="item in passStatusData"
            :key="item.value"
            :name="item.value"
            >{{ item.label }}</van-radio
          >
        </van-radio-group>
      </template>
    </van-field>
    <div v-if="isAdd" class="details-page__footer-button-wrapper--fixed">
      <van-button
        :disabled="loading.status"
        :loading="loading.status"
        :loading-text="loading.text"
        block
        native-type="submit"
        round
        type="info"
        >提交</van-button
      >
    </div>
  </van-form>
</template>

<script>
import { DELAY_PASS_STATUS } from "./utils/constant";
import { operateMessage } from "@/utils";
import { loading } from "@/utils/constant";
import { mapState } from "vuex";
import {
  delayApproveTrouble,
  getTroubleDelayInfoById
} from "@/api/psm/trouble";

export default {
  props: ["id"],
  data() {
    return {
      passStatusData: DELAY_PASS_STATUS,
      loading: { ...loading },
      delayFixInfo: {
        applyDate: "",
        reason: ""
      },
      info: {
        applyStatus: "1",
        troubleId: ""
      }
    };
  },
  computed: {
    ...mapState({
      userInfo: state => state.login.userInfo
    })
  },
  inject: {
    detailsComponents: {
      type: Object,
      default: () => ({})
    }
  },
  watch: {},
  created() {
    this.isAdd = JSON.parse(this.$route.query.add);
    if (this.isAdd) {
      this.getInfo();
    }
  },
  methods: {
    async getInfo() {
      try {
        this.delayFixInfo = await getTroubleDelayInfoById(this.id);
      } catch (e) {
        console.log(e);
      }
    },
    async onSubmit() {
      this.info.troubleId = this.id;
      try {
        const ret = await delayApproveTrouble(this.info);
        operateMessage(ret, "审批");
        if (ret) this.$router.back();
      } catch (e) {
        console.log("onSubmit -> e", e);
      }
    }
  }
};
</script>

<style></style>
